<template>
	<div class="wrap">
		<div class="title" v-html="info.title"></div>
		<p>咳嗽是气道受刺激后人体的一种基本的保护性反射，但某些情况下会变得持续，在成人患者中若时间超过8周则称为<span>慢性咳嗽（CC）</span>，咳嗽敏感性增高是慢性咳嗽患者的常见特征，其相关机制的探索，在咳嗽神经反射中有哪些研究进展，敬请观看：</p>
		<div class="video">
			<video :src="info.video_url" :poster="poster" controls webkit-playsinline='true' playsinline='true'></video>
		</div>
		<p class="bottom">在《中国难治性慢性咳嗽的诊断与治疗专家共识》中，将<span>难治性慢性咳嗽</span>定义为：咳嗽时长>8周，（1）经过推荐的规范检查和治疗后，原因仍然不明的慢性咳嗽；（2）经过针对慢性咳嗽已知病因的经验性治疗，咳嗽仍不能缓解的慢性咳嗽；（3）部分有慢性咳嗽病因的检查证据，但治疗效果差，咳嗽持续的慢性咳嗽。</p>

		<!-- 参考文献 -->
		<Reference :info="info.reference"></Reference>
	</div>
</template>

<script>
import { Toast } from 'vant';
import { getRoadDetail } from '../../common/api/index'
import Reference from '../../components/Reference'
export default {
	data() {
		return {
			info: {},
			poster: ''
		}
	},
	created () {
	},
	mounted () {
		this.id = this.$route.query.id
		this.findInfo()

		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, article_id: this.id, module_id: 6, page: this.$route.path})
		this.$wxShare._wxConfigJSSDK()
	},
	methods: {
		/**
		 * 获取列表数据
		 */
		findInfo () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			getRoadDetail({id: this.id}).then(res => {
				Toast.clear()
				console.log(res)
				if (res.code === 200) {
					that.info = res.data.data
					setTimeout(function () {
						that.poster = res.data.data.video_url + '?vframe/jpg/offset/1'
					}, 100)
				} else {
					Toast(res.msg)
				}
			})
		}
	},
	components: {
		Reference
	}
}
</script>

<style scoped lang="scss">
.wrap{
	width: 100%;
	margin-bottom: 60px;
	background: #000;
	.title{
		width: 90%;
		padding: 17px 0;
		box-sizing: border-box;
		color: #49FCFC;
		font-size: 32px;
		text-align: center;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 18px;
		border: 2px solid #fff;
		margin: auto;
		margin-top: 100px;
	}

	p{
		width: 88%;
		font-size: 26px;
		line-height: 48px;
		margin: auto;
		margin-top: 56px;
		
		span{
			color: #FFDA00;
			font-weight: 800;
		}
	}

	.bottom{
		width: auto;
		padding: 0 6%;
		background: url(../../assets/img/road/detail-bg.png) bottom center no-repeat;
		background-size: 100%;
		margin-bottom: 40px;
	}

	.video{
		width: 88%;
		margin: auto;
		margin-top: 20px;
		position: relative;
		video{
			width: 100%;
			border-radius: 20px;
		}

		.play{
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			img{
				width: 70px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}
</style>
